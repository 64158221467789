// JS dependency
var slick = require('slick-carousel');

// CSS dependency
import '../../node_modules/slick-carousel/slick/slick.css';

$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  arrows: false,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        adaptiveHeight: true,
        arrows: true,
        appendArrows: '#slick-home-arrows',
        prevArrow: '<button class="arrow-prev"><i class="material-icons">arrow_backward</i></button>',
        nextArrow: '<button class="arrow-next"><i class="material-icons">arrow_forward</i></button>',
      }
    },
  ]
});

// Example animations
setTimeout(function() {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick-home').on('afterChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick-home').on('beforeChange', function(event, slick, currentSlide) {
  $('#slick-home .slick-active').removeClass('animation');
});

$('#slick-blog').slick({
  slidesToShow: 4,
  slidesToScroll: 2,
  dots: true,
  fade: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,

      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,

      }
    },

  ]
});
