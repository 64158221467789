// JS dependency
var datepicker = require('jquery-ui/ui/widgets/datepicker');
var local = $("html").attr('lang').slice(0,2);
if(local === 'fr'){
  $.datepicker.regional['fr'] = require('jquery-ui/ui/i18n/datepicker-fr');
}

// CSS dependencies
import '../../node_modules/jquery-ui/themes/base/theme.css';
import '../../node_modules/jquery-ui/themes/base/datepicker.css';

var numberOfMonths = window.matchMedia("(min-width: 900px)").matches ? 2 : 1;

// Input Start
$("#datepicker-start").datepicker({
  showOn: "both",
  buttonText: "<span class='sprite arrow-white'></span><span class='sprite picker-date'></span>",
  //buttonImage: 'theme/images/icons/gmap-marker.png',
  buttonImageOnly: false,
  defaultDate: "+1w",
  numberOfMonths: numberOfMonths,
  minDate: 0,
  maxDate: '1Y',
  dateFormat: 'dd/mm/yy',
  onClose: function( selectedDate ) {
    $("#datepicker-end").datepicker( "option", "minDate", selectedDate );
  }
});

// Input End
$("#datepicker-end").datepicker({
  showOn: "both",
  buttonText: "<span class='sprite arrow-white'></span><span class='sprite picker-date'></span>",
  //buttonImage: 'theme/images/icons/gmap-marker.png',
  buttonImageOnly: false,
  defaultDate: "+1w",
  maxDate: '1Y',
  numberOfMonths: numberOfMonths,
  dateFormat: 'dd/mm/yy'
});

// fonction changement date debut
$('#datepicker-start').change(function() {
  // on récupère la date de début
  var date_fin = $('#datepicker-start').datepicker('getDate');
  //on ajoute 7 jours à la date de début pour obtenir la dete de fin
  date_fin.setDate(date_fin.getDate() + 7);
  //maj date fin dans datapicker
  $('#datepicker-end').datepicker('setDate', date_fin);
});


$(".input-datepicker").each(function(index) {
  $(this).datepicker({
    defaultDate: "+1w",
    numberOfMonths: 1,
    minDate: '-90Y',
    maxDate: $(this).data('minimum'),
    changeMonth: true,
    changeYear: true,
    yearRange: "-90:+0",
    showOn: "both",
    buttonText: "<i class='material-icons'>calendar_today</i>",
    dateFormat: 'dd/mm/yy',
    //buttonImage: "/theme/images/icons/datepicker-icon.png"
  });
});