function reservation_toggle() {
  var form = $('#resa-page-wp');
  var btn = $('#trigger-resa-page');
  form.hide();
  btn.on('click', function(){
    btn.parent().hide();
    form.show();
    return false;
  });
}

function reservation_toggle_bis() {
  var form = $('#resa-page-wp');
  var btn = $('#trigger-resa-nav');
  form.hide();
  btn.on('click', function(){
    form.toggle();
    btn.toggleClass('open');
    return false;
  });
}

function faq_toggle(){
  $('.item-faq .content').hide();
  $('.item-faq .button').on('click',function(){
    var isOpen = $(this).parents().eq(1).hasClass('open');
    if(isOpen){
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
    }else{
      $('.item-faq .content').hide();
      $('.item-faq').removeClass('open');
      $(this).parent().next().toggle();
      $(this).parents().eq(1).toggleClass('open');
    }
    return false;
  });
}


// Reservation toggle Elm
function resa_toggle_elm (){
  var elm_airport = $('#toggle-airport');
  if ($('#checkbox-departure').is(':checked')) {
    elm_airport.hide();
  }
  $('#checkbox-departure').on('change',function(){
    elm_airport.toggle();
    if ($(this).is(':checked')) {
      elm_airport.hide();
      $('#airport-start').trigger('change');
    }
  });
  $('#airport-start').on('change',function(){
    if ($('#checkbox-departure').is(':checked')) {
      $('#airport-end').val($('#airport-start').val()).change();
    }
  });


  var elm_promo = $('#toggle-promo');
  elm_promo.hide();
  $('#checkbox-promo').on('change',function(){
    elm_promo.toggle();
  });
}

function resa_promo_mobile(){
  var viewport = $(window).width();
  var elm = $('#toggle-promo');
  if(viewport <= 767) {
    elm.insertBefore('#prepend-promo');
  }
}

// Navigation mobile
function nav_mobile() {
  $('#nav-mobile-trigger').on('click', function() {
    $('body').toggleClass('menu-open');
    $('#nav-main').toggle();
  });
}

// Navigation size
function nav_size() {
  var position = $(window).scrollTop();
  if (position > 220) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
}

// Navigation sub menu
function nav_sub_menu() {
  var viewport = $(window).width();
  if (viewport < 576) {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      $(this).next().toggle();
      return false;
    });
  } else {
    $('#nav-main li[data-navsub] > a').on('click', function(event) {
      var navSub = $(this).parent();
      var isFocus = navSub.hasClass('focus');
      $('#nav-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      event.stopPropagation();
      return false;
    });
    $('html').click(function() {
      $('#nav-main li[data-navsub]').removeClass('focus');
    });
  }
}

// Scroll top button
function scroll_top() {
  $('#btn-back-top').fadeOut(0);
  $(window).scroll(function() {
    if ($(this).scrollTop() > 220) {
      $('#btn-back-top').fadeIn(300);
    } else {
      $('#btn-back-top').fadeOut(300);
    }
  });
  $('#btn-back-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, '500');
    return false;
  });
}

function nav_form_resa_blink() {
  $('.resa-anchor').on('click', function () {
    $('#trigger-resa-page').trigger('click');
    $('#resa').fadeOut(500).fadeIn(500).fadeOut(500).fadeIn(500);
  });
}


// Initialisation
nav_mobile();
nav_sub_menu();
nav_size();
scroll_top();
resa_toggle_elm();
resa_promo_mobile();
reservation_toggle();
faq_toggle();
reservation_toggle_bis();
nav_form_resa_blink();
$(window).on('resize',function(){
  resa_promo_mobile();
});
